import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";

export default ({ location }) => (

	<Layout 
    path={location.pathname}>

    <Title
      subtitle="Not Found"
      title="Hmm, how did you get here?" />

	</Layout>

)